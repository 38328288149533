var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50 md-medium-size-100" },
      [
        _c(
          "tabs",
          {
            attrs: {
              "tab-name": ["Profile", "Settings", "Options"],
              "color-button": "warning",
            },
          },
          [
            _c(
              "h4",
              {
                staticClass: "title",
                attrs: { slot: "header-title" },
                slot: "header-title",
              },
              [
                _vm._v("Navigation Pills - "),
                _c("small", { staticClass: "description" }, [
                  _vm._v("Horizontal Tabs"),
                ]),
              ]
            ),
            _c("template", { slot: "tab-pane-1" }, [
              _vm._v(
                " Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dramatically visualize customer directed convergence without revolutionary ROI. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. "
              ),
            ]),
            _c("template", { slot: "tab-pane-2" }, [
              _vm._v(
                " Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dramatically maintain clicks-and-mortar solutions without functional solutions. "
              ),
            ]),
            _c("template", { slot: "tab-pane-3" }, [
              _vm._v(
                " Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dynamically innovate resource-leveling customer service for state of the art customer service. "
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50 md-medium-size-100" },
      [
        _c(
          "tabs",
          {
            attrs: {
              "tab-name": ["Profile", "Settings", "Options"],
              "flex-column": "",
              "color-button": "success",
            },
          },
          [
            _c(
              "h4",
              {
                staticClass: "title",
                attrs: { slot: "header-title" },
                slot: "header-title",
              },
              [
                _vm._v("Navigation Pills - "),
                _c("small", { staticClass: "description" }, [
                  _vm._v("Vertical Tabs"),
                ]),
              ]
            ),
            _c("template", { slot: "tab-pane-1" }, [
              _vm._v(
                " Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dramatically visualize customer directed convergence without revolutionary ROI. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. "
              ),
            ]),
            _c("template", { slot: "tab-pane-2" }, [
              _vm._v(
                " Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dramatically maintain clicks-and-mortar solutions without functional solutions. "
              ),
            ]),
            _c("template", { slot: "tab-pane-3" }, [
              _vm._v(
                " Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dynamically innovate resource-leveling customer service for state of the art customer service. "
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50 md-medium-size-100" },
      [
        _c(
          "md-card",
          [
            _c("md-card-header", [
              _c("h4", { staticClass: "title" }, [
                _vm._v("Collapsible Accordion"),
              ]),
            ]),
            _c(
              "md-card-content",
              [
                _c(
                  "collapse",
                  {
                    attrs: {
                      collapse: [
                        "Collapsible Group Item #1",
                        "Collapsible Group Item #2",
                        "Collapsible Group Item #3",
                      ],
                      icon: "keyboard_arrow_down",
                      "color-collapse": "success",
                    },
                  },
                  [
                    _c("template", { slot: "md-collapse-pane-1" }, [
                      _c("p", [
                        _vm._v(
                          "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS."
                        ),
                      ]),
                    ]),
                    _c("template", { slot: "md-collapse-pane-2" }, [
                      _c("p", [
                        _vm._v(
                          "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS."
                        ),
                      ]),
                    ]),
                    _c("template", { slot: "md-collapse-pane-3" }, [
                      _c("p", [
                        _vm._v(
                          "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS."
                        ),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-50 md-medium-size-100" },
      [
        _c(
          "tabs",
          {
            attrs: {
              "tab-name": ["Home", "Settings"],
              "tab-icon": ["dashboard", "schedule"],
              "flex-column": "",
              "nav-pills-icons": "",
              "color-button": "success",
            },
          },
          [
            _c(
              "h4",
              {
                staticClass: "title",
                attrs: { slot: "header-title" },
                slot: "header-title",
              },
              [
                _vm._v("Navigation Pills Icons - "),
                _c("small", { staticClass: "description" }, [
                  _vm._v("Vertical Tabs"),
                ]),
              ]
            ),
            _c("template", { slot: "tab-pane-1" }, [
              _vm._v(
                " Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dramatically visualize customer directed convergence without revolutionary ROI. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. "
              ),
            ]),
            _c("template", { slot: "tab-pane-2" }, [
              _vm._v(
                " Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                " Dramatically maintain clicks-and-mortar solutions without functional solutions. "
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-66 mx-auto md-medium-size-100" },
      [
        _c(
          "tabs",
          {
            staticClass: "page-subcategories",
            attrs: {
              "tab-name": [
                "Description",
                "Location",
                "Legal Info",
                "Help Center",
              ],
              "tab-icon": ["info", "location_on", "gavel", "help_outline"],
              "nav-pills-icons": "",
              plain: "",
              "color-button": "warning",
            },
          },
          [
            _c(
              "h3",
              {
                staticClass: "title text-center",
                attrs: { slot: "header-title" },
                slot: "header-title",
              },
              [_vm._v("Page Subcategories")]
            ),
            _c(
              "template",
              { slot: "tab-pane-1" },
              [
                _c(
                  "md-card",
                  [
                    _c("md-card-header", [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v("Description about product"),
                      ]),
                      _c("p", { staticClass: "category" }, [
                        _vm._v("More information here"),
                      ]),
                    ]),
                    _c("md-card-content", [
                      _vm._v(
                        " Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI. "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "template",
              { slot: "tab-pane-2" },
              [
                _c(
                  "md-card",
                  [
                    _c("md-card-header", [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v("Location of the product"),
                      ]),
                      _c("p", { staticClass: "category" }, [
                        _vm._v("More information here"),
                      ]),
                    ]),
                    _c("md-card-content", [
                      _vm._v(
                        " Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions. "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "template",
              { slot: "tab-pane-3" },
              [
                _c(
                  "md-card",
                  [
                    _c("md-card-header", [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v("Legal info of the product"),
                      ]),
                      _c("p", { staticClass: "category" }, [
                        _vm._v("More information here"),
                      ]),
                    ]),
                    _c("md-card-content", [
                      _vm._v(
                        " Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer service for state of the art customer service. "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "template",
              { slot: "tab-pane-4" },
              [
                _c(
                  "md-card",
                  [
                    _c("md-card-header", [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v("Help center "),
                      ]),
                      _c("p", { staticClass: "category" }, [
                        _vm._v("More information here"),
                      ]),
                    ]),
                    _c("md-card-content", [
                      _vm._v(
                        " Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer service for state of the art customer service. "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }